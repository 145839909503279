<template>
    <ValidationProvider
        :name="validationName"
        :rules="validationRules"
        v-slot="{ errors }"
    >
        <v-autocomplete
            :clearable="inputIsClearable"
            dense
            :disabled="inputDisabled"
            :error-messages="errors"
            filled
            hide-details="auto"
            :hint="inputHint"
            :id="id"
            :item-text="optionText"
            :item-value="optionValue"
            :items="optionItems"
            :loading="inputIsLoading"
            open-on-clear
            outlined
            :placeholder="inputPlaceholder"
            :prepend-inner-icon="inputIcon"
            :readonly="inputReadOnly"
            return-object
            :value="inputValue"
            v-bind="$attrs"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
            @click:clear="$emit('click:clear', $event)"
            @input.native="$emit('input', $event.target.value)"
        >
            <template v-slot:append-item>
                <div v-intersect="onIntersect" />
            </template>
        </v-autocomplete>
    </ValidationProvider>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$emit("intersect", "intersectionEvent");
            }
        },
    },
    name: "customAutoCompleteField",
    props: {
        errors: { default: null },
        id: { default: null, type: String },
        inputDisabled: { default: false, type: Boolean },
        inputHint: { default: null, type: String },
        inputIcon: { default: null, type: String },
        inputIconAppend: { default: null, type: String },
        inputIsClearable: { default: false, type: Boolean },
        inputIsLoading: { default: false, type: Boolean },
        inputPlaceholder: { default: "Placeholder", type: String },
        inputReadOnly: { default: false, type: Boolean },
        inputValue: { default: "" },
        intersectCallback: { default: null },
        optionItems: [],
        optionText: { default: "name", type: String },
        optionValue: { default: "id", type: String },
        validationName: { default: "ValidationProviderName", type: String },
        validationRules: { default: null, type: String },
    },

    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

